import { ENTITY_TYPES } from '@/configs/participants';
import {postApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder, getApiRequestBuilder} from '@/utils/RequestBuilder';

const END_POINT_ENTITY = 'entity';

export function getEntityDataByINN(inn, userEntityType) {
	return postApiRequestBuilder(`${END_POINT_ENTITY}/profile-by-inn`)
		.send({
			inn: inn,
			userEntityType,
		})
}

export function entitySave(entityData) {
	switch(entityData.entityType) {
	case ENTITY_TYPES.INDIVIDUAL:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/person`).send(entityData);
	case ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/entrepreneur`).send(entityData);
	case ENTITY_TYPES.LEGAL_ENTITY:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/legal`).send(entityData);
	}
}

export function addEntityPerson() {
	return postApiRequestBuilder(`${END_POINT_ENTITY}/person`).send();
}

export function entitySavePatch(entityData) {
	switch(entityData.entityType) {
	case ENTITY_TYPES.INDIVIDUAL:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/person/patch`).send(entityData);
	case ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/entrepreneur/patch`).send(entityData);
	case ENTITY_TYPES.LEGAL_ENTITY:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/legal/patch`).send(entityData);
	}
}

export function entityApplyPatch(entityData) {
	switch(entityData.entityType) {
	case ENTITY_TYPES.INDIVIDUAL:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/person/patch/apply`).send(entityData);
	case ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/entrepreneur/patch/apply`).send(entityData);
	case ENTITY_TYPES.LEGAL_ENTITY:
		return putApiRequestBuilder(`${END_POINT_ENTITY}/legal/patch/apply`).send(entityData);
	}
}

export function entityDeletePatch(entityId) {
	return deleteApiRequestBuilder(`${END_POINT_ENTITY}/${entityId}/patch`).send();
}

export function entityMoveToDraftPatch(entityId) {
	return postApiRequestBuilder(`${END_POINT_ENTITY}/${entityId}/patch/moderate/redraft`).send();
}

export function entityMoveToModerationPatch(entityId) {
	return putApiRequestBuilder(`${END_POINT_ENTITY}/${entityId}/patch/moderate`).send();
}

export function entityAppealModerationPatch(entityId, appealText) {
	return postApiRequestBuilder(`${END_POINT_ENTITY}/${entityId}/patch/moderate/appeal`).send({appealText});
}

export function entityRejectModerationPatch({ entityId, rejectReason }) {
	return postApiRequestBuilder(`${END_POINT_ENTITY}/${entityId}/patch/moderate/reject`).send({rejectReason});
}

export function removeEntity(id) {
	return deleteApiRequestBuilder(`${END_POINT_ENTITY}/${id}`).send();
}

export function getEntityData(entityId) {
	return getApiRequestBuilder(`${END_POINT_ENTITY}/${entityId}`).send();
}
export function getEntityHistoryData(entityId) {
	return getApiRequestBuilder(`${END_POINT_ENTITY}/${entityId}/history`).send().then(r => r.data.items);
}

export function getEntityByParticipantData(participantId) {
	return getApiRequestBuilder(`${END_POINT_ENTITY}/participant/${participantId}`).send();
}

export function createEntityChangeOwner(data) {
	return postApiRequestBuilder(`${END_POINT_ENTITY}/${data.entityId}/owner`).send(data);
}

export function createAdminEntityChangeOwner(data) {
	return postApiRequestBuilder(`/admin/${END_POINT_ENTITY}/${data.entityId}/owner`).send(data);
}

export function getEntityChangeOwner(entityId) {
	return getApiRequestBuilder(`/admin/${END_POINT_ENTITY}/${entityId}/owner`).send();
}

export function rejectEntityChangeOwner(entityId, requestId, rejectReason) {
	return deleteApiRequestBuilder(`/admin/${END_POINT_ENTITY}/${entityId}/owner/${requestId}`).send({rejectReason});
}

export function applyEntityChangeOwner(entityId, requestId) {
	return putApiRequestBuilder(`/admin/${END_POINT_ENTITY}/${entityId}/owner/${requestId}`).send();
}