export const INVEST_TYPES = {
	UNKNOWN: 'UNKNOWN',
	STOCK: 'STOCK',
	LOAN: 'LOAN',
	CONVERTIBLE_LOAN: 'CONVERTIBLE_LOAN',
	UCP: 'UCP',
};

export const PROJECT_TYPES = {
	PITCH: 'Драфт-проект',
	STOCK: 'Акции',
	LOAN: 'Заём',
	CONVERTIBLE_LOAN: 'Конвертируемый заём',
	UCP: 'УЦП',
};

export const PROJECT_TYPES_OFFERS = {
	PITCH: 'PITCH',
	PROJECT: 'PROJECT',
};

export const PROJECT_TYPES_OFFER_NAMES = {
	PITCH: 'Драфт-проект',
	PROJECT: 'Инвестиционное предложение',
};

export const INVEST_TYPE_NAMES = {
	UNKNOWN: 'Выберите вид привлечения инвестиций',
	STOCK: 'Выпуск акций',
	LOAN: 'Заём',
	CONVERTIBLE_LOAN: 'Конвертируемый заём',
	UCP: 'УЦП',
};

export const INVEST_TYPE_OFFER_DOCS = {
	UNKNOWN: '',
	STOCK: 'STOCK_OFFER',
	LOAN: 'LOAN_OFFER',
	CONVERTIBLE_LOAN: 'CONV_LOAN_OFFER',
	UCP: 'UCP_OFFER',
};

export const DOC_TYPES_CODES = {
	CORPORATE_AGREEMENT: 'CORPORATE_AGREEMENT',
	SHAREHOLDERS_AGREEMENT: 'SHAREHOLDERS_AGREEMENT',
};

export const INVEST_TYPES_ARR = [
	{value: 'LOAN', previewName: 'Заём'},
	{value: 'CONVERTIBLE_LOAN', previewName: 'Конвертируемый заём'},
	{value: 'STOCK', previewName: 'Акции'},
	{value: 'UCP', previewName: 'УЦП'},
];

export const COLLATERAL_TYPES = {
	PERSONAL_GUARANTEE: 'PERSONAL_GUARANTEE',
	THIRD_PARTY_GUARANTEE: 'THIRD_PARTY_GUARANTEE',
	PLEDGE_REAL_ESTATE: 'PLEDGE_REAL_ESTATE',
	PLEDGE_PERSONAL_ESTATE: 'PLEDGE_PERSONAL_ESTATE',
	PLEDGE_OBLIGATION_RIGHTS: 'PLEDGE_OBLIGATION_RIGHTS',
	SHAREHOLDER_GUARANTEE: 'SHAREHOLDER_GUARANTEE',
};

export const COLLATERAL_TYPES_NAMES = {
	SHIP: 'SHIP',
};

export const COLLATERAL_TYPE_NAMES = {
	PERSONAL_GUARANTEE: 'Персональные гарантии',
	THIRD_PARTY_GUARANTEE: 'Гарантии третьей стороны',
	PLEDGE_REAL_ESTATE: 'Гарантия залогом недвижимости',
	PLEDGE_PERSONAL_ESTATE: 'Гарантия залогом движимого имущества',
	PLEDGE_OBLIGATION_RIGHTS: 'Гарантия залогом обязательств',
	SHAREHOLDER_GUARANTEE: 'Гарантии акционеров',
};

export const COLLATERAL_TYPES_SIGNED_BY_THIRD_PARTY = [
	COLLATERAL_TYPES.THIRD_PARTY_GUARANTEE,
	COLLATERAL_TYPES.SHAREHOLDER_GUARANTEE
];

export const ENTITY_TYPES = {
	INDIVIDUAL: 'INDIVIDUAL',
	INDIVIDUAL_ENTREPRENEUR: 'INDIVIDUAL_ENTREPRENEUR',
	LEGAL_ENTITY: 'LEGAL_ENTITY',
};

export const COLLATERAL_TYPE_DOCS = {
	PERSONAL_GUARANTEE: 'GUARANTEE_OFFER',
	THIRD_PARTY_GUARANTEE: 'GUARANTEE_OFFER',
	PLEDGE_REAL_ESTATE: 'REAL_ESTATE_OFFER',
	PLEDGE_PERSONAL_ESTATE: 'MOVABLE_PROPERTY_OFFER',
	PLEDGE_OBLIGATION_RIGHTS: 'OBLIGATIONS_OFFER',
	SHAREHOLDER_GUARANTEE: 'GUARANTEE_OFFER',
};

export const INVEST_STATUSES = {
	DRAFT: 'DRAFT',
	PREPARED: 'PREPARED',
	PUBLISHED: 'PUBLISHED',
	REJECTED: 'REJECTED',
	COLLECTED: 'COLLECTED',
	CANCELED: 'CANCELED',
	RELEASED: 'RELEASED',
	CLOSED: 'CLOSED',
	FINISHED: 'FINISHED',
};

export const SPECIAL_STATUSES = {
	FORCED_REPAYMENT: 'FORCED_REPAYMENT',
	FORCED_CONVERSION: 'FORCED_CONVERSION',
};

export const PROJECT_VIEW_TYPES = {
	OWNER: "OWNER",
	OPERATOR: "OPERATOR",
	INVESTOR: "INVESTOR",
	USER: "USER"
};

export const LOAN_PUPRPOSES = {
	INVESTMENT_LOAN: 'INVESTMENT_LOAN',
	GOVERMENT_CONTRACT: 'GOVERMENT_CONTRACT',
	REPLENISHMENT_CAPITAL: 'REPLENISHMENT_CAPITAL',
};

export const OPERATIONS_TYPES = {
	WITHDRAWAL: 'Вывод средств',
	TOPUP: 'Пополнение лицевого счета',
	INVESTMENT: 'Инвестирование',
	REFUND_INVESTMENT: 'Возврат инвестиций',
	REFUND_INTEREST: 'Возврат процентов',
	EXTRA: 'Оплата доп услуг',
	TAX: 'Удержание налога',
};

export const CONV_CALC_OPTIONS = {
	PROPOSED_SHARE: 'PROPOSED_SHARE',
	MATURITY_CAP: 'MATURITY_CAP',
};

export const CALCULATION_OPTIONS_CONVERTIBLE_LOAN = [
	{label: 'Предлагаемая доля в УК', value: 'PROPOSED_SHARE'},
	{label: 'Maturity Cap (pre-money)', value: 'MATURITY_CAP'},
];
