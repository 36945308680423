<template>
	<div class="ui-sidebar custom-scrollbar">
		<UiSkeleton :loading="loading" show-mode="if">
			<ul v-if="options.length && showDefaultSidebar" class="ui-sidebar__list" >
				<li 
					v-for="(option, index) in options" 
					:key="index" 
					class="ui-sidebar__list-item"
				>
					<button
						:class="{
							'ui-sidebar__list-link': true,
							'ui-sidebar__list-link--selected': option[selectedKey] === selectedOption[selectedKey],
							'chatted': chatStyle,
						}"
						@click="changeOption(option)"
					>
						<slot name="label" v-bind="{ option, active: option[selectedKey] === selectedOption[selectedKey], iconsData }">
							<div v-if="showDate" class="ui-sidebar__link-date">
								{{ formatDate(option.created, "DD.MM.YYYY") ?? '' }}
							</div>
							{{ option.name }}
							<UiSmartIcon
								v-if="iconsData[option.status]"
								:name="iconsData[option.status].name"
								:color="option[selectedKey] === selectedOption[selectedKey] ? '#fff' : iconsData[option.status].color"
								:size="20"
								class="ui-sidebar__link-img" 
							/>
						</slot>
					</button>
				</li>
			</ul>
			<ul v-else class="ui-sidebar__list--mobile" >
				<li 
					v-for="(option, index) in options" 
					:key="index" 
					class="ui-sidebar__mobile-item"
				>
					<slot name="mobile-item" v-bind="{ option, active: option[selectedKey] === selectedOption[selectedKey], iconsData }"/>
				</li>
			</ul>
			<div v-if="!options.length" style="margin-bottom: 10px;">{{ noOptionsTitle }}</div>
		</UiSkeleton>	


		<slot v-if="mediaForDesktop.value" name="bottom" />

	</div>
</template>
  
<script>
import { formatDate } from '@/utils/format';
import {STATUS} from '@configs/participants';

export default {
	name: 'UiSidebar',

	inject: ['mediaForDesktop'],

	model: {
		prop: "currentValue",
		event: "change",
	},

	props: {
		/**
		 * Текущий выбранный value в сайд-баре, объект,
		 * который должен иметь два обязательных свойства: (name, status), 
		 * при изменении имен статуса, необходимо также изменить их в iconsData
		 */
		currentValue: {
			type: Object,
			required: true,
		},
		/**
		 * Массив объектов опций сайдбара
		 * @type {Array<{ name: string, status: string }>}
		 */
		options: {
			type: Array,
			required: true,
		},
		/**
		 * Тайтл если опции отсутствуют
		 */
		noOptionsTitle: {
			type: String,
			default: 'Опции отсутствуют',
		},
		/**
		 * Если true, показывает дату создания айтема
		 */
		showDate: {
			type: Boolean,
			default: false,
		},
		/**
		 * Признак loading
		 */
		loading: {
			type: Boolean,
			default: false,
		},
		/**
		 * Признак chatStyle, для отображение в стилях чата
		 */
		chatStyle: {
			type: Boolean,
			default: false,
		},
		/**
		 * Признак изменяемости при смене адаптива
		 */
		 changeInAdaptive: {
			type: Boolean,
			default: false,
		},
		/**
		 * Поля активности
		 */
		selectedKey: {
			type: String,
			default: 'name',
		},
	},

	data() {
		const data = {
			selectedOption: this.currentValue ? this.currentValue : this.options[0],
			iconsData: {},
		}

		data.iconsData[STATUS.ACTIVE] = {
			name: 'icons-check-1',
			color: '#818586'
		};

		data.iconsData[STATUS.PREPARED] = data.iconsData[STATUS.ON_115FZ] = {
			name: 'icons-clock',
			color: '#818586'
		};

		data.iconsData[STATUS.REJECTED] = {
			name: 'icons-attention',
			color: '#DE2D31'
		};

		data.iconsData[STATUS.BLOCKED] = {
			name: 'icons-off',
			color: '#DE2D31'
		};

		return data;
	},

	computed: {
		showDefaultSidebar() {
			return this.changeInAdaptive ? this.mediaForDesktop.value : true;
		}
	},

	watch: {
		currentValue(newVal) {
			this.selectedOption = newVal;
		}
	},

	methods: {
		formatDate,
		changeOption(option) {
			this.selectedOption = option;
			this.$emit('change', this.selectedOption);
		},
	}
};
</script>
