/* eslint-disable no-useless-escape */
//! ESLint ругается на некоторые регулярки

import { helpers, required, between, requiredIf } from '@vuelidate/validators';

import ERRORS from './errors';

const subtractYears = (count, date = new Date()) =>
	new Date(date.setFullYear(date.getFullYear() - count));

const VALIDATORS = {
	regex: (reg, errorMessage) => {
		return helpers.withMessage(errorMessage, helpers.regex(reg));
	},
	after: (year, errorMessage) => {
		return helpers.withMessage(
			errorMessage,
			(v) => new Date(v) > new Date(`${year}`)
		);
	},
	before: (year, errorMessage) => {
		return helpers.withMessage(
			errorMessage,
			(v) => new Date(v) < new Date(`${year}`)
		);
	},
};

const RULES = {
	required: helpers.withMessage(ERRORS.required, required),
	requiredIf: (...args) =>
		helpers.withMessage(ERRORS.required, requiredIf(...args)),
	email: VALIDATORS.regex(
		/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
		ERRORS.incorrectEmail
	),
	phone: VALIDATORS.regex(
		/^(\+?\d{1,3}( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{2}[-]?\d{2}$/,
		ERRORS.incorrectPhone
	),
	passportNumber: VALIDATORS.regex(
		/\d{4} \d{6}/,
		ERRORS.incorrectPassportNumber
	),
	password: VALIDATORS.regex(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
		ERRORS.incorrectPassword
	),
	passportCode: VALIDATORS.regex(
		/^\d{3}-\d{3}$/,
		ERRORS.incorrectPassportCode
	),
	date: helpers.withMessage(
		ERRORS.incorrectDate,
		(v) => new Date(v) > subtractYears(120) && new Date(v) < new Date()
	),
	percent: helpers.withMessage(
		ERRORS.incorrectPercent,
		between(0, 100)
	),
	adult: helpers.withMessage(
		ERRORS.underage,
		(v) => new Date(v) < subtractYears(18)
	),
	presentDate: VALIDATORS.after(1990, ERRORS.incorrectDate),
	inn: VALIDATORS.regex(/^(\d{10}|\d{12})$/, ERRORS.incorrectInn),
	innIP: VALIDATORS.regex(/^(\d{12})$/, ERRORS.incorrectIPInn),
	innWithdrawal: validateInn,
	innWithdrawalFiz: validateInnFiz,
	validateBik: validateBik,
	validateKpp: validateKpp,
	validateKs: validateKs,
	validateRs: validateRs,
	snils: VALIDATORS.regex(/^(?:[- ]*\d){11}$/, ERRORS.incorrectSnils),
	url: VALIDATORS.regex(
		/^(https?:\/\/)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([/?#][^\s]*)?$/,
		ERRORS.incorrectURL
	),
	truthy: helpers.withMessage(
		ERRORS.incorrectData,
		(value) => value === true
	),
	confirmRequired: helpers.withMessage(
		ERRORS.confirmRequired,
		(v) => v === true
	),
	color: VALIDATORS.regex(
		/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
		'Введите цвет'
	),
	accountNumber: helpers.withMessage(
		ERRORS.accountNumber,
		v => helpers.len(v) === 20,
	),
};

function checkDigitInn(inn, error, result) {
	if (inn.length === 0) {
		return;
	}
	let checkDigit = function (inn, coefficients) {
		let n = 0;
		for (let i in coefficients) {
			n += coefficients[i] * inn[i];
		}
		return parseInt(n % 11 % 10);
	};
	switch (inn.length) {
	case 10:
		let n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
		if (n10 === parseInt(inn[9])) {
			result = true;
		}
		break;
	case 12:
		let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
		let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
		if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
			result = true;
		}
		break;
	}
	if (!result) {
		error.message = 'Неправильное контрольное число';
	}
}

function validateInn(inn, error = {}) {
	let result = false;
	if (typeof inn === 'number') {
		inn = inn.toString();
	} else if (typeof inn !== 'string') {
		inn = '';
	}
	if (/[^0-9]/.test(inn)) {
		error.message = 'ИНН может состоять только из цифр';
	} else if ([0, 10, 12].indexOf(inn.length) === -1) {
		error.message = 'ИНН может состоять только из 10 или 12 цифр';
	} else {
		checkDigitInn(inn, error, result);
	}
	if (!result) return error.message;
}

function validateInnFiz(inn, error = {}) {
	let result = false;
	if (typeof inn === 'number') {
		inn = inn.toString();
	} else if (typeof inn !== 'string') {
		inn = '';
	}
	if (/[^0-9]/.test(inn)) {
		error.message = 'ИНН может состоять только из цифр';
	} else if ([0, 12].indexOf(inn.length) === -1) {
		error.message = 'ИНН физ лица может состоять только из 12 цифр';
	} else {
		checkDigitInn(inn, error, result);
	}
	if (!result) return error.message;
}

function validateBik(bik, defaultValidate = true, error = {}) {
	let result = false;
	if (typeof bik === 'number') {
		bik = bik.toString();
	} else if (typeof bik !== 'string') {
		bik = '';
	}
	if (/[^0-9]/.test(bik)) {
		error.message = 'БИК может состоять только из цифр';
	} else if ([0, 9].indexOf(bik.length) === -1) {
		error.message = 'БИК может состоять только из 9 цифр';
	} else {
		result = true;
	}
	if (defaultValidate) {
		return result;
	} else {
		if (!result) {
			return error.message;
		}
	}
}

function validateKpp(kpp, error = {}) {
	var result = false;
	if (typeof kpp === 'number') {
		kpp = kpp.toString();
	} else if (typeof kpp !== 'string') {
		kpp = '';
	}
	if ([0, 9].indexOf(kpp.length) === -1) {
		error.message = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)';
	} else if (kpp && !/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
		error.message = 'Неправильный формат КПП';
	} else {
		result = true;
	}
	if (!result) return error.message;
}

function validateKs(ks, bik, error = {}) {
	var result = false;
	if (validateBik(bik, true, error)) {
		if (typeof ks === 'number') {
			ks = ks.toString();
		} else if (typeof ks !== 'string') {
			ks = '';
		}
		if (ks.length === 0) {
			return;
		}
		if (/[^0-9]/.test(ks)) {
			error.message = 'К/С может состоять только из цифр';
		} else if (ks.length !== 20) {
			error.message = 'К/С может состоять только из 20 цифр';
		} else {
			var bikKs = '0' + bik.toString().slice(4, 6) + ks;
			var checksum = 0;
			var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
			for (var i in coefficients) {
				checksum += coefficients[i] * (bikKs[i] % 10);
			}
			if (checksum % 10 === 0) {
				result = true;
			} else {
				error.message = 'Неправильное контрольное число';
			}
		}
	} else {
		return;
	}
	if (!result) return error.message;
}

function validateRs(rs, bik, error = {}) {
	var result = false;
	if (validateBik(bik, true, error)) {
		if (typeof rs === 'number') {
			rs = rs.toString();
		} else if (typeof rs !== 'string') {
			rs = '';
		}
		if (rs.length === 0) {
			return;
		}
		if (/[^0-9]/.test(rs)) {
			error.message = 'Р/С может состоять только из цифр';
		} else if (rs.length !== 20) {
			error.message = 'Р/С может состоять только из 20 цифр';
		} else {
			var bikRs = bik.toString().slice(-3) + rs;
			var checksum = 0;
			var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
			for (var i in coefficients) {
				checksum += coefficients[i] * (bikRs[i] % 10);
			}
			if (checksum % 10 === 0) {
				result = true;
			} else {
				error.message = 'Неправильное контрольное число';
			}
		}
	} else {
		return;
	}
	if (!result) return error.message;
}

export default RULES;

export { VALIDATORS };
